
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';
export default {
  // 运营管理
  operationManage: [
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1005493'),
      icon: '&#xe67b;',
      path: '/statistics',
      menuKey: 'supplier_statistics',
      hideCrumbs: true, // 隐藏面包屑
      guidanceModule: false, // 指引模块（默认开启）  true：开启指引模块    false  不开启指引模块
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006982'),
      icon: '&#xe668;',
      type: 'supplierOrder',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006983'),
          path: '/supplierOrderList',
          menuKey: 'supplier_supplierOrderList',
          guidanceModule: true
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1004628'),
          path: '/stockList',
          menuKey: 'supplier_stockList',
          guidanceModule: true,
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1005740'),
              path: '/initiateStocking',
              menuKey: 'supplier_initiateStocking',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006984'),
              path: '/stockOrderDetails',
              menuKey: 'supplier_stockOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006985'),
          path: '/shipmentRecord',
          menuKey: 'supplier_shipmentRecord',
          guidanceModule: true,
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006986'),
              path: '/shipmentRecordDetails',
              menuKey: 'supplier_shipmentRecordDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1005717'),
              path: '/additionalGoods',
              menuKey: 'supplier_additionalGoods',
              menuHide: true // 菜单不展示
            }
          ]
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006987'),
      icon: '&#xe678;',
      type: 'productCenter',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1005814'),
          path: '/productCenter/productGoods',
          menuKey: 'supplier_productCenter_productGoods',
          guidanceModule: true,
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1002662'),
              path: '/viewSku',
              menuKey: 'yms_viewSku',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006988'),
              path: '/productDetails',
              menuKey: 'supplier_productDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1005861'),
              path: '/collectProducts',
              menuKey: 'yms_collectProducts',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006989'),
          path: '/productCenter/productBrand',
          menuKey: 'supplier_productCenter_productBrand',
          guidanceModule: true,
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006990'),
      icon: '&#xe68c;',
      type: 'platformAuthorization',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006991'),
          path: '/platformAuthorization',
          menuKey: 'supplier_platformAuthorization',
          guidanceModule: true,
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006992'),
      path: '/reconciliationManage',
      menuKey: 'supplier_reconciliationManage',
      icon: '&#xe665;',
      type: 'reconciliationManage',
      guidanceModule: true,
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1003529'),
          path: '/reconciliationBillDetails',
          menuKey: 'supplier_reconciliationBillDetails',
          menuHide: true // 菜单不展示
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1000290'),
      path: '/messageCenter',
      menuKey: 'supplier_messageCenter',
      jurisdictionHide: true, // 不需要权限判断
      icon: '&#xe8be;',
      type: 'messageCenter',
      guidanceModule: true,
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006977'),
          path: '/messageMailboxes',
          menuKey: 'supplier_messageMailboxes',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006993'),
      icon: '&#xe6e3;',
      type: 'accountManage',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1000645'),
          path: '/accountManage/basicInformation',
          menuKey: 'supplier_accountManage_basicInformation',
          guidanceModule: true,
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006978'),
          path: '/accountManage/management',
          menuKey: 'supplier_accountManage_management',
          guidanceModule: true,
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1003450'),
          path: '/accountManage/collectionAccount',
          menuKey: 'supplier_accountManage_collectionAccount',
          guidanceModule: true,
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006979'),
      icon: '&#xe664;',
      type: 'importExportTask',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006980'),
          path: '/importTask',
          menuKey: 'supplier_importTask'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006981'),
          path: '/exportTask',
          menuKey: 'supplier_exportTask'
        }
      ]
    }
  ],
  // 仓储管理
  warehouseManage: [
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1002597'),
      path: '/wms/searchPackage',
      menuKey: 'supplier_wms_searchPackage',
      icon: '&#xe665;',
      type: 'searchPackage',
      guidanceModule: true,
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006994'),
          path: '/wms/searchPackageDetails',
          menuKey: 'supplier_wms_searchPackageDetails',
          menuHide: true // 菜单不展示
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006188'),
          path: '/addManualSupplierOrder',
          menuKey: 'supplier_addManualSupplierOrder',
          menuHide: true // 菜单不展示
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006995'),
      path: '/wms/packingManage',
      menuKey: 'supplier_wms_packingManage',
      icon: '&#xe61b;',
      type: 'packingManage',
      guidanceModule: true,
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1000588'),
          path: '/wms/exwarehouse',
          menuKey: 'supplier_wms_exwarehouse',
          menuHide: true, // 菜单不展示
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006043'),
              path: '/wms/scanEx',
              menuKey: 'supplier_wms_scanEx',
              menuHide: true // 菜单不展示
            }
          ]
        },
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1002219'),
      path: '/wms/inventoryLevels',
      menuKey: 'supplier_wms_inventoryLevels',
      icon: '&#xe65f;',
      type: 'inventoryLevels',
      guidanceModule: true,
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006996'),
      icon: '&#xe63c;',
      type: 'orderPicking',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002562'),
          guidanceModule: true,
          path: '/wms/generateOrderList',
          menuKey: 'supplier_wms_generateOrderList'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006933'),
          path: '/wms/generateOrderDetails',
          menuKey: 'supplier_wms_generateOrderDetails',
          menuHide: true // 菜单不展示
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006934'),
          guidanceModule: true,
          path: '/wms/pickList',
          menuKey: 'supplier_wms_pickList'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002527'),
          path: '/wms/pickListDetails',
          menuKey: 'supplier_wms_pickListDetails',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006997'),
      icon: '&#xe630;',
      type: 'outboundPperation',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006998'),
          path: '/wms/sortOrderSetting',
          menuKey: 'supplier_wms_sortOrderSetting',
          guidanceModule: true,
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1001961'),
          path: '/wms/sorting',
          menuKey: 'supplier_wms_sorting',
          guidanceModule: true,
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006171'),
          path: '/wms/packWorking',
          menuKey: 'supplier_wms_packWorking',
          guidanceModule: true,
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006979'),
      icon: '&#xe664;',
      type: 'importExportTask',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006980'),
          path: '/importTask',
          menuKey: 'supplier_importTask'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006981'),
          path: '/exportTask',
          menuKey: 'supplier_exportTask'
        }
      ]
    }
  ],
};
